import React from "react";
import NewHeader from "../modules/NewHeader";

const Index = () => {
  return (
    <div>
      <NewHeader />
      <h1>ASSET GUID</h1>
    </div>
  );
};

export default Index;
