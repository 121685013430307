import React, { useState } from "react";
import ReactWaves from "@dschoon/react-waves";
import { AudioPlayerLoader } from "./Loader";
import Tooltip from "@material-ui/core/Tooltip";

const PlayerButton = ({
  tooltipLabel,
  tooltipPlacement = "top",
  children,
  onClick,
  ...otherProps
}) => {
  return (
    <Tooltip title={tooltipLabel} placement={tooltipPlacement} {...otherProps}>
      <button
        onClick={onClick}
        style={{ border: "none", backgroundColor: "transparent" }}
      >
        {children}
      </button>
    </Tooltip>
  );
};

const AUDIO_PEAKS = [
  0.159, -0.191, 0.006, -0.327, 0.153, -0.123, 0.107, -0.054, 0.071, -0.079,
  0.282, -0.036, 0.163, -0.173, 0.118, -0.088, 0.255, -0.142, 0.062, -0.093,
  0.283, -0.105, 0.017, -0.223, 0.092, -0.112, 0.113, -0.231, 0.043, -0.265,
  0.025, -0.478, 0.087, -0.405, 0.209, -0.436, 0.154, -0.091, 0.116, -0.277,
  0.066, -0.185, 0.19, -0.184, 0.088, -0.213, 0.086, -0.237, 0.1, -0.096, 0.198,
  -0.144, 0.198, -0.138, 0.094, -0.084, 0.138, -0.139, 0.25, -0.296, 0.067,
  -0.192, 0.078, -0.09, 0.166, -0.2, 0.088, -0.064, 0.176, -0.052, 0.084,
  -0.131, 0.219, -0.441, 0.229, -0.16, 0.393, -0.051, 0.391, -0.121, 0.164,
  -0.205, 0.176, -0.079, 0.127, -0.237, 0.13, -0.194, 0.216, -0.311, 0.058,
  -0.244, 0.04, -0.357, 0.064, -0.368, 0.124, -0.088, 0.141, -0.27, 0.153,
  -0.104, 0.071, -0.313, 0.04, -0.058, 0.035, -0.132, 0.133, -0.051, 0.411,
  -0.443, 0.09, -0.185, 0.276, -0.102, 0.259, -0.238, 0.273, -0.128, 0.254,
  -0.102, 0.381, -0.104, 0.206, -0.159, 0.16, -0.111, 0.087, -0.06, 0.084,
  -0.162, 0.312, -0.066, 0.171, -0.245, 0.097, -0.295, 0.265, -0.154, 0.147,
  -0.399, 0.214, -0.136, 0.02, -0.254, 0.111, -0.281, 0.255, -0.249, 0.194,
  -0.072, 0.199, -0.092, 0.211, -0.122, 0.266, -0.104, 0.193, -0.236, 0.082,
  -0.197, 0.21, -0.035, 0.301, -0.169, 0.292, -0.177, 0.192, -0.167, 0.097,
  -0.104, 0.244, -0.134, 0.24, -0.105, 0.245, -0.041, 0.55, -0.26, 0.147, -0.07,
  0.201, -0.164, 0.058, -0.297, 0.332, -0.104, 0.342, -0.168, 0.144, -0.14,
  0.075, -0.077, 0.114, -0.18, 0.117, -0.08, 0.1, -0.095, 0.303, -0.042, 0.394,
  -0.228, 0.337, -0.248, 0.339, -0.002, 0.024, -0.089, 0.057, -0.109, 0.283,
  -0.098, 0.208, -0.166, 0.029, -0.155, 0.178, -0.041, 0.102, -0.332, 0.068,
  -0.116, 0.105, -0.047, 0.37, -0.087, 0.277, -0.099, 0.116, -0.143, 0.245,
  -0.185, 0.059, -0.328, 0.159, -0.061, 0.298, -0.358, 0.34, -0.104, 0.176,
  -0.291, 0.221, -0.124, 0.044, -0.002, 0.384, -0.195, 0.082, -0.125, 0.078,
  -0.133, 0.442, -0.07, 0.245, -0.268, 0.328, -0.121, 0.047, -0.16, 0.573,
  -0.21, 0.379, -0.347, 0.162, -0.343, 0.104, -0.122, 0.461, -0.147, 0.452,
  -0.154, 0.253, -0.173, 0.177, -0.152, 0.177, -0.188, 0.474, -0.148, 0.429,
  -0.219, 0.1, -0.166, 0.146, -0.075, 0.131, -0.098, 0.098, -0.28, 0.105,
  -0.151, 0.228, -0.103, 0.154, -0.214, 0.292, -0.101, 0.096, -0.12, 0.48,
  -0.171, 0.242, -0.091, 0.156, -0.192, 0.314, -0.066, 0.196, -0.208, 0.139,
  -0.298, 0.223, -0.093, 0.266, -0.057, 0.181, -0.26, 0.269, -0.088, 0.235,
  -0.192, 0.544, -0.17, 0.229, -0.118, 0.219, -0.338, 0.145, -0.063, 0.123,
  -0.077, 0.178, -0.042, 0.147, -0.253, 0.076, -0.249, 0.297, -0.134, 0.203,
  -0.223, 0.041, -0.146, 0.182, -0.154, 0.209, -0.193, 0.141, -0.068, 0.277,
  -0.198, 0.186, -0.28, 0.239, -0.19, 0.287, -0.074, 0.193, -0.15, 0.31, -0.244,
  0.05, -0.071, 0.253, -0.179, 0.155, -0.087, 0.163, -0.049, 0.075, -0.063,
  0.038, -0.079, 0.104, -0.09, 0.098, -0.307, 0.226, -0.205, 0.084, -0.125,
  0.125, -0.017, 0.081, -0.057, 0.139, -0.065, 0.057, -0.046, 0.241, -0.145,
  0.136, -0.068, 0.129, -0.085, 0.152, -0.129, 0.116, -0.189, 0.158, -0.204,
  0.142, -0.103, 0.244, -0.03, 0.099, -0.116, 0.065, -0.202, 0.278, -0.069,
  0.082, -0.412, 0.06, -0.218, 0.133, -0.065, 0.151, -0.153, 0.114, -0.036,
  0.123, -0.149, 0.131, -0.083, 0.231, -0.139, 0.12, -0.11, 0.167, -0.085,
  0.195, -0.231, 0.171, -0.261, 0.069, -0.216, 0.184, -0.142, 0.177, -0.127,
  0.161, -0.179, 0.294, -0.138, 0.093, -0.161, 0.167, -0.25, 0.143, -0.158,
  0.365, -0.188, 0.148, -0.17, 0.058, -0.411, 0.142, -0.139, 0.089, -0.23,
  0.075, -0.113, 0.192, -0.104, 0.1, -0.19, 0.13, -0.132, 0.105, -0.321, 0.173,
  -0.371, 0.193, -0.37, 0.157, -0.039, 0.07, -0.059, 0.433, -0.059, 0.301,
  -0.074, 0.153, -0.078, 0.121, -0.056, 0.065, -0.18, 0.129, -0.233, 0.114,
  -0.02, 0.069, -0.216, 0.171, -0.304, 0.16, -0.03, 0.079, -0.249, 0.216,
  -0.279, 0.071, -0.124, 0.172, -0.2, 0.127, -0.163, 0.122, -0.307, 0.294,
  -0.309, 0.214, -0.05, 0.143, -0.049, 0.245, -0.105, 0.075, -0.109, 0.073,
  -0.056, 0.082, -0.003, 0.396, -0.109, 0.213, -0.094, 0.163, -0.215, 0.181,
  -0.22, 0.062, -0.337, 0.356, -0.23, 0.229, -0.278, 0.122, -0.086, 0.146,
  -0.094, 0.133, -0.093, 0.093, -0.087, 0.02, -0.068, 0.386, -0.241, 0.213,
  -0.018, 0.174, -0.098, 0.228, -0.081, 0.606, -0.107, 0.232, -0.01, 0.179,
  -0.057, 0.042, -0.188, 0.088, -0.234, 0.124, -0.094, 0.162, -0.49, 0.098,
  -0.105, 0.193, -0.173, 0.019, -0.097, 0.166, -0.096, 0.475, -0.449, 0.355,
  -0.081, 0.281, -0.106, 0.259, -0.057, 0.603, -0.081, 0.18, -0.347, 0.155,
  -0.075, 0.389, -0.139, 0.189, -0.207, 0.074, -0.241, 0.007, -0.106, 0.102,
  -0.116, 0.152, -0.291, 0.494, -0.038, 0.094, -0.323, 0.148, -0.107, 0.233,
  -0.307, 0.281, -0.143, 0.171, -0.033, 0.043, -0.17, 0.117, -0.459, 0.2,
  -0.046, 0.054, -0.22, 0.069, -0.054, 0.038, -0.113, 0.075, -0.1, 0.244,
  -0.238, 0.193, -0.041, 0.083, -0.145, 0.028, -0.272, 0.189, -0.083, 0.135,
  -0.228, 0.331, -0.207, 0.17, -0.156, 0.219, -0.111, 0.196, -0.16, 0.176,
  -0.067, 0.085, -0.178, 0.209, -0.092, 0.099, -0.321, 0.07, -0.092, 0.056,
  -0.115, 0.175, -0.188, 0.095, -0.234, 0.171, -0.126, 0.098, -0.13, 0.076,
  -0.091, 0.009, -0.141, 0.243, -0.121, 0.219, -0.377, 0.153, -0.305, 0.598,
  -0.083, 0.157, -0.053, 0.198, -0.106, 0.11, -0.106, 0.189, -0.018, 0.116,
  -0.052, 0.452, -0.066, 0.111, -0.062, 0.043, -0.113, 0.299, -0.108, 0.097,
  -0.106, 0.025, -0.053, 0.112, -0.064, 0.247, -0.054, 0.327, -0.323, 0.325,
  -0.064, 0.14, -0.129, 0.003, -0.249, 0.254, -0.13, 0.078, -0.123, 0.142,
  -0.109, 0.153, -0.112, 0.132, -0.082, 0.367, -0.116, 0.145, -0.112, 0.076,
  -0.103, 0.092, -0.148, 0.303, -0.116, 0.107, -0.06, 0.2, -0.034, 0.035,
  -0.099, 0.005, -0.205, 0.178, -0.132, 0.318, -0.119, 0.041, -0.099, 0.197,
  -0.022, 0.161, -0.198, 0.102, -0.196, 0.238, -0.121, 0.345, -0.123, 0.268,
  -0.057, 0.173, -0.035, 0.11, -0.032, 0.113, -0.192, 0.063, -0.09, 0.139,
  -0.027, 0.13, -0.072, 0.175, -0.07, 0.156, -0.163, 0.126, -0.044, 0.094,
  -0.036, 0.08, -0.037, 0.042, -0.226, 0.026, -0.251, 0.029, -0.019, 0.169,
  -0.049, 0.22, -0.325, 0.158, -0.303, 0.145, -0.166, 0.058, -0.197, 0.088,
  -0.203, 0.24, -0.175, 0.115, -0.094, 0.061, -0.192, 0.101, -0.211, 0.126,
  -0.088, 0.205, -0.004, 0.153, -0.214, 0.377, -0.017, 0.076, -0.046, 0.157,
  -0.124, 0.142, -0.408, 0.1, -0.037, 0.151, -0.166, 0.16, -0.095, 0.032,
  -0.107, 0.09, -0.118, 0.016, -0.092, 0.085, -0.054, 0.173, -0.146, 0.199,
  -0.205, 0.065, -0.043, 0.082, -0.185, 0.108, -0.202, 0.114, -0.284, 0.196,
  -0.097, 0.146, -0.114, 0.122, -0.098, 0.137, -0.158, 0.105, -0.031, 0.121,
  -0.115, 0.462, -0.184, 0.091, -0.118, 0.038, -0.066, 0.058, -0.149, 0.09,
  -0.15, 0.106, -0.162, 0.116, -0.028, 0.374, -0.104, 0.083, -0.04, 0.162,
  -0.007, 0.025, -0.106, 0.03, -0.043, 0.401, -0.029, 0.11, -0.135, 0.2, -0.117,
  0.073, -0.09, 0.01, -0.373, 0.01, -0.183, 0.218, -0.147, 0.028, -0.131, 0.075,
  -0.138, 0.144, -0.156, 0.079, -0.215, 0.1, -0.107, 0.064, -0.051, 0.058,
  -0.058, 0.104, -0.217, 0.077, -0.168, 0.209, -0.046, 0.273, -0.105, 0.067,
  -0.063, 0.061, -0.041, 0.084, -0.103, 0.048, -0.298, 0.203, -0.111, 0.368,
  -0.121, 0.124, -0.143, 0.081, -0.123, 0.375, -0.175, 0.417, -0.227, 0.159,
  -0.025, 0.256, -0.18, 0.141, -0.057, 0.081, -0.151, 0.336, -0.068, 0.178,
  -0.35, 0.238, -0.277, 0.14, -0.059, 0.132, -0.275, 0.038, -0.19, 0.274, -0.17,
  0.065, -0.085, 0.294, -0.078, 0.015, -0.162, 0.093, -0.104, 0.067, -0.145,
  0.297, -0.106, 0.183, -0.204, 0.055, -0.177, 0.109, -0.119, 0.14, -0.085,
  0.138, -0.052, 0.01, -0.103, 0.036, -0.083, 0.175, -0.031, 0.275, -0.114,
  0.06, -0.262, 0.162, -0.08, 0.248, -0.071, 0.49, -0.12, 0.107, -0.229, 0.065,
  -0.157, 0.237, -0.117, 0.227, -0.085, 0.109, -0.089, 0.095, -0.122, 0.233,
  -0.144, 0.076, -0.011, 0.203, -0.132, 0.296, -0.073, 0.112, -0.124, 0.155,
  -0.104, 0.241, -0.274, 0.055, -0.047, 0.102, -0.056, 0.163, -0.071, 0.118,
  -0.017, 0.071, -0.115, 0.113, -0.191, 0.073, -0.085, 0.057, -0.252, 0.117,
  -0.05, 0.236, -0.244, 0.001, -0.167, 0.229, -0.18, 0.059, -0.097, 0.241,
  -0.23, 0.157, -0.133, 0.367, -0.11, 0.06, -0.089, 0.073, -0.056, 0.104,
  -0.066, 0.117, -0.07, 0.109, -0.115, 0.062, -0.23, 0.138, -0.134, 0.162,
  -0.169, 0.205, -0.138, 0.132, -0.158, 0.157, -0.2, 0.339, -0.215, 0.329, -0.2,
  0.122, -0.222, 0.08, -0.208, 0.339, -0.088, 0.087, -0.055, 0.082, -0.257,
  0.053, -0.235, 0.199, -0.151, 0.188, -0.235, 0.169, -0.233, 0.24, -0.06,
  0.059, -0.256, 0.153, -0.179, 0.134, -0.181, 0.364, -0.007, 0.144, -0.16,
  0.178, -0.3, 0.167, -0.187, 0.085, -0.139, 0.172, -0.14, 0.138, -0.247, 0.067,
  -0.074, 0.145, -0.12, 0.056, -0.166, 0.176, -0.088, 0.139, -0.14, 0.101,
  -0.069, 0.151, -0.131, 0.074, -0.041, 0.033, -0.034, 0.083, -0.054, 0.064,
  -0.059, 0.131, -0.157, 0.243, -0.181, 0.062, -0.079, 0.123, -0.218, 0.102,
  -0.082, 0.102, -0.093, 0.045, -0.057, 0.098, -0.216, 0.043, -0.149, 0.011,
  -0.063, 0.053, -0.27, 0.189, -0.173, 0.129, -0.127, 0.221, -0.169, 0.055,
  -0.253, 0.055, -0.16, 0.081, -0.171, 0.084, -0.015, 0.036, -0.105, 0.171,
  -0.152, 0.099, -0.025, 0.15, -0.093, 0.138, -0.032, 0.038, -0.041, 0.194,
  -0.376, 0.055, -0.121, 0.239, -0.131, 0.146, -0.285, 0.063, -0.314, 0.092,
  -0.284, 0.094, -0.309, 0.135, -0.141, 0.128, -0.255, 0.036, -0.201, 0.059,
  -0.069, 0.092, -0.109, 0.038, -0.08, 0.137, -0.094, 0.06, -0.034, 0.047,
  -0.034, 0.085, -0.056, 0.213, -0.134, 0.098, -0.115, 0.02, -0.096, 0.027,
  -0.046, 0.147, -0.028, 0.204, -0.09, 0.229, -0.141, 0.157, -0.222, 0.223,
  -0.119, 0.071, -0.089, 0.29, -0.138, 0.006, -0.256, 0.064, -0.087, 0.043,
  -0.117, 0.141, -0.07, 0.13, -0.09, 0.127, -0.126, 0.271, -0.114, 0.269,
  -0.262, 0.029, -0.101, 0.157, -0.31, 0.169, -0.104, 0.32, -0.108, 0.188,
  -0.038, 0.048, -0.052, 0.035, -0.106, 0.073, -0.021, 0.068, -0.143, 0.094,
  -0.098, 0.05, -0.035, 0.165, -0.013, 0.165, -0.046, 0.083, -0.032, 0.003,
  -0.059, 0.059, -0.097, 0.162, -0.027, 0.21, -0.102, 0.308, -0.196, 0.104,
  -0.067, 0.033, -0.019, 0.32, -0.068, 0.027, -0.053, 0.159, -0.051, 0.185,
  -0.077, 0.075, -0.055, 0.037, -0.142, 0.239, -0.015, 0.124, -0.103, 0.4,
  -0.13, 0.029, -0.203, 0.254, -0.074, 0.137, -0.099, 0.088, -0.12, 0.368,
  -0.123, 0.075, -0.217, 0.089, -0.191, 0.236, -0.181, 0.068, -0.08, 0.09,
  -0.084, 0.106, -0.039, 0.157, -0.105, 0.095, -0.224, 0.004, -0.095, 0.109,
  -0.133, 0.137, -0.037, 0.257, -0.188, 0.083, -0.073, 0.069, -0.031, 0.054,
  -0.385, 0.071, -0.368, 0.238, -0.124, 0.303, -0.11, 0.118, -0.136, 0.073,
  -0.144, 0.021, -0.142, 0.135, -0.124, 0.111, -0.234, 0.132, -0.146, 0.346,
  -0.076, 0.341, -0.221, 0.38, -0.25, 0.151, -0.198, 0.21, -0.07, 0.086, -0.204,
  0.168, -0.163, 0.062, -0.21, 0.022, -0.129, 0.123, -0.056, 0.112, -0.03,
  0.123, -0.138, 0.263, -0.152, 0.154, -0.099, 0.129, -0.154, 0.028, -0.138,
  0.229, -0.084, 0.035, -0.055, 0.027, -0.023, 0.143, -0.152, 0.189, -0.053,
  0.138, -0.019, 0.046, -0.113, 0.064, -0.192, 0.08, -0.016, 0.174, -0.046,
  0.153, -0.034, 0.099, -0.067, 0.156, -0.085, 0.133, -0.108, 0.063, -0.145,
  0.112, -0.065, 0.197, -0.133, 0.206, -0.079, 0.053, -0.078, 0.274, -0.035,
  0.017, -0.137, 0.165, -0.062, 0.398, -0.048, 0.117, -0.083, 0.111, -0.185,
  0.006, -0.188, 0.034, -0.123, 0.08, -0.183, 0.061, -0.245, 0.038, -0.276,
  0.263, -0.016, 0.042, -0.211, 0.158, -0.26, 0.068, -0.149, 0.285, -0.343,
  0.111, -0.079, 0.102, -0.186, 0.089, -0.409, 0.078, -0.053, 0.089, -0.268,
  0.032, -0.115, 0.236, -0.045, 0.279, -0.164, 0.314, -0.057, 0.361, -0.067,
  0.125, -0.161, 0.172, -0.106, 0.001, -0.136, 0.112, -0.139, 0.09, -0.122,
  0.206, -0.09, 0.085, -0.259, 0.159, -0.121, 0.121, -0.166, 0.165, -0.074,
  0.168, -0.067, 0.301, -0.078, 0.176, -0.082, 0.022, -0.128, 0.184, -0.326,
  0.223, -0.061, 0.089, -0.047, 0.221, -0.059, 0.065, -0.161, 0.061, -0.136,
  0.087, -0.062, 0.109, -0.112, 0.128, -0.125, 0.076, -0.014, 0.139, -0.101,
  0.13, -0.042, 0.133, -0.225, 0.009, -0.19, 0.115, -0.168, 0.066, -0.1, 0.15,
  -0.241, 0.18, -0.119, 0.123, -0.235, 0.225, -0.052, 0.017, -0.186, 0.219,
  -0.236, 0.138, -0.21, 0.369, -0.136, 0.365, -0.382, 0.421, -0.111, 0.138,
  -0.205, 0.288, -0.096, 0.067, -0.096, 0.066, -0.089, 0.186, -0.03, 0.212,
  -0.175, 0.031, -0.091, 0.048, -0.059, 0.12, -0.218, 0.082, -0.256, 0.093,
  -0.078, 0.079, -0.036, 0.211, -0.22, 0.14, -0.188, 0.178, -0.183, 0.136,
  -0.161, 0.181, -0.007, 0.186, -0.065, 0.123, -0.059, 0.103, -0.283, 0.35,
  -0.269, 0.112, -0.166, 0.021, -0.104, 0.255, -0.36, 0.25, -0.011, 0.105,
  -0.249, 0.101, -0.105, 0.162, -0.047, 0.153, -0.047, 0.382, -0.041, 0.169,
  -0.162, 0.111, -0.062, 0.218, -0.008, 0.03, -0.07, 0.062, -0.099, 0.144,
  -0.065, 0.199, -0.309, 0.21, -0.185, 0.082, -0.088, 0.094, -0.164, 0.234,
  -0.18, 0.145, -0.117, 0.141, -0.051, 0.16, -0.138, 0.153, -0.049, 0.039,
  -0.067, 0.265, -0.011, 0.265, -0.214, 0.011, -0.189, 0.149, -0.202, 0.16,
  -0.468, 0.143, -0.285, 0.19, -0.416, 0.177, -0.112, 0.065, -0.182, 0.032,
  -0.04, 0.402, -0.082, 0.027, -0.193, 0.341, -0.043, 0.083, -0.212, 0.099,
  -0.157, 0.297, -0.077, 0.281, -0.08, 0.071, -0.259, 0.374, -0.135, 0.535,
  -0.033, 0.235, -0.043, 0.135, -0.138, 0.086, -0.118, 0.088, -0.059, 0.452,
  -0.099, 0.34, -0.086, 0.138, -0.205, 0.298, -0.069, 0, -0.128, 0.175, -0.117,
  0.299, -0.12, 0.467, -0.091, 0.133, -0.082, 0.268, -0.073, 0.2, -0.19, 0.15,
  -0.202, 0.063, -0.184, 0.098, -0.079, 0.123, -0.144, 0.144, -0.096, 0.147,
  -0.096, 0.198, -0.094, 0.204, -0.09, 0.079, -0.186, 0.288, -0.395, 0.533,
  -0.026, 0.106, -0.085, 0.031, -0.185, 0.114, -0.079, 0.103, -0.405, 0.352,
  -0.109, 0.033, -0.348, 0.032, -0.258, 0.227, -0.21, 0.262, -0.099, 0.218,
  -0.463, 0.15, -0.121, 0.119, -0.047, 0.116, -0.127, 0.07, -0.316, 0.046,
  -0.14, 0.144, -0.099, 0.177, -0.089, 0.315, -0.014, 0.054, -0.096, 0.141,
  -0.147, 0.268, -0.112, 0.121, -0.126, 0.092, -0.093, 0.062, -0.098, 0.04,
  -0.097, 0.105, -0.252, 0.118, -0.064, 0.032, -0.247, 0.038, -0.406, 0.239,
  -0.191, 0.096, -0.184, 0.085, -0.079, 0.341, -0.187, 0.286, -0.075, 0.14,
  -0.142, 0.151, -0.141, 0.14, -0.096, 0.168, -0.198, 0.065, -0.039, 0.243,
  -0.205, 0.127, -0.063, 0.218, -0.14, 0.009, -0.098, 0.104, -0.12, 0.085,
  -0.069, 0.103, -0.199, 0.116, -0.097, 0.524, -0.125, 0.205, -0.166, 0.092,
  -0.144, 0.094, -0.05, 0.081, -0.087, 0.326, -0.068, 0.227, -0.116, 0.012,
  -0.296, 0.007, -0.171, 0.237, -0.046, 0.384, -0.158, 0.073, -0.166, 0.179,
  -0.068, 0.095, -0.252, 0.217, -0.28, 0.299, -0.268, 0.335, -0.068, 0.033,
  -0.185, 0.074, -0.04, 0.207, -0.05, 0.119, -0.109, 0.055, -0.285, 0.117,
  -0.049, 0.258, -0.184, 0.063, -0.091, 0.109, -0.049, 0.167, -0.007, 0.129,
  -0.033, 0.467, -0.007, 0.23, -0.155, 0.072, -0.217, 0.032, -0.165, 0.019,
  -0.051, 0.038, -0.168, 0.014, -0.097, 0.073, -0.11, 0.081, -0.094, 0.006,
  -0.034, 0.121, -0.018, 0.121, -0.068, 0.248, -0.072, 0.057, -0.104, 0.378,
  -0.084, 0.028, -0.155, 0.163, -0.097, 0.101, -0.03, 0.014, -0.08, 0.181,
  -0.084, 0.064, -0.04, 0.054, -0.119, 0.157, -0.033, 0.063, -0.032, 0.058,
  -0.176, 0.103, -0.158, 0.165, -0.249, 0.041, -0.171, 0.07, -0.097, 0.087,
  -0.118, 0.079, -0.157, 0.42, -0.066, 0.17, -0.135, 0.06, -0.148, 0.023,
  -0.063, 0.247, -0.17, 0.045, -0.122, 0.423, -0.04, 0.019, -0.037, 0.306,
  -0.049, 0.057, -0.057, 0.127, -0.056, 0.056, -0.034, 0.081, -0.046, 0.233,
  -0.134, 0.225, -0.093, 0.266, -0.103, 0.24, -0.323, 0.274, -0.054, 0.025,
  -0.377, 0.26, -0.142, 0.292, -0.119, 0.129, -0.132, 0.084, -0.137, 0.26,
  -0.193, 0.168, -0.193, 0.123, -0.319, 0.164, -0.024, 0.218, -0.074, 0.157,
  -0.139, 0.021, -0.192,
];

const REACT_WAVE_OPTIONS = {
  backend: "MediaElement",
  barGap: 1,
  barWidth: 2,
  barHeight: 2,
  cursorWidth: 0,
  fillParent: true,
  height: 60,
  hideScrollbar: true,
  progressColor: "#4F49E2",
  responsive: false,
  waveColor: "#9E9E9E",
};

const AudioPlayer = (props) => {
  const [play, togglePlay] = useState(false);
  const [star, setStar] = useState(false);
  return (
    <div className="wave-form">
      <div className="wrapper" style={{ position: "relative" }}>
        {props.isLoading && <AudioPlayerLoader progressLabel="please wait ..." />}
        <div
          className="control-wrap"
          style={{
            flexDirection: "column",
            justifyContent: "space-evenly",
            paddingTop: "1.2rem",
          }}
        >
          <PlayerButton
            tooltipLabel={`${play ? "PAUSE" : "PLAY"}`}
            tooltipPlacement="right"
            arrow
            onClick={() => togglePlay(!play)}
          >
            <img
              src={`${play ? "/static/pause.svg" : "/static/play.svg"}`}
              alt="play"
              title="play"
              width="45"
              height="45"
            />
          </PlayerButton>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlayerButton tooltipLabel="STARS" tooltipPlacement="top" arrow>
              <img
                src={star ? "/static/img/starfill.svg" : "/static/img/star.svg"}
                alt=""
                title=""
                width="25"
                height="25"
              />
            </PlayerButton>

            <PlayerButton
              tooltipLabel="NEW PATTERN"
              tooltipPlacement="top"
              arrow
            >
              <img
                src={"/static/img/edit.svg"}
                alt=""
                title=""
                width="20"
                height="20"
              />
            </PlayerButton>

            <PlayerButton tooltipLabel="RELATION" tooltipPlacement="top" arrow>
              <img
                src={"/static/img/bubble.svg"}
                alt=""
                title=""
                width="20"
                height="20"
              />
            </PlayerButton>

            <PlayerButton
              tooltipLabel="5CF11FC7C8504B6FBC26B4D164026C"
              tooltipPlacement="top"
              arrow
            >
              <img
                src={"/static/img/copywave.svg"}
                alt=""
                title=""
                width="20"
                height="20"
              />
            </PlayerButton>
          </div>
        </div>
        <div className="audio-waves">
          <ReactWaves
            audioPeaks={AUDIO_PEAKS}
            audioFile="http://traffic.libsyn.com/joeroganexp/p1196.mp3?dest-id=19997"
            className="react-waves"
            options={REACT_WAVE_OPTIONS}
            volume={1}
            zoom={0.06}
            // playing={play}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
