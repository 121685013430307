// CHANGE_REQUEST_BODY
export const CHANGE_REQUEST_BODY = "CHANGE_REQUEST_BODY";
// CHANGE_REQUEST_LINK
export const CHANGE_REQUEST_LINK = "CHANGE_REQUEST_LINK";
// CHANGE_TREE_DATA
export const CHANGE_TREE_DATA = "CHANGE_TREE_DATA";
// SAVE_TREE_ID
export const SAVE_TREE_ID = "SAVE_TREE_ID";
// UPDATE_TREE_ID
export const UPDATE_TREE_ID = "UPDATE_TREE_ID";
// DELETE_TREE_ELEMENT
export const DELETE_TREE_ELEMENT = "DELETE_TREE_ELEMENT";
// DUPLICATE_TREE_ELEMENT
export const DUPLICATE_TREE_ELEMENT = "DUPLICATE_TREE_ELEMENT";
// ADD_TREE_ELEMENT_TITLE
export const ADD_TREE_ELEMENT_TITLE = "ADD_TREE_ELEMENT_TITLE";
// CHANGE_TREE_ELEMENT_TITLE
export const CHANGE_TREE_ELEMENT_TITLE = "CHANGE_TREE_ELEMENT_TITLE";
// CHANGE_REQUEST_BODY_STATIC
export const CHANGE_REQUEST_BODY_STATIC = "CHANGE_REQUEST_BODY_STATIC";
// ADD_TREE_DATA
export const ADD_TREE_DATA = "ADD_TREE_DATA";

// SEND
export const SEND_REQUEST = "SEND_REQUEST";
export const SEND_PUT_REQUEST = "SEND_PUT_REQUEST";
export const SEND_SUCCESS = "SEND_SUCCESS";
export const SEND_RESET = "SEND_RESET";
export const SEND_FAILURE = "SEND_FAILURE";

// KEY_CLOAK
export const KEY_CLOAK_REQUEST = "KEY_CLOAK_REQUEST";
export const KEY_CLOAK_SUCCESS = "KEY_CLOAK_SUCCESS";
export const KEY_CLOAK_RESET = "KEY_CLOAK_RESET";
export const KEY_CLOAK_FAILURE = "KEY_CLOAK_FAILURE";

//CHANGE_TABS
export const CHANGE_TABS = "CHANGE_TABS";
//CHANGE_POSITION_VALUE
export const CHANGE_POSITION_VALUE = "CHANGE_POSITION_VALUE";
//CHANGE_TONEJS
export const CHANGE_TONEJS = "CHANGE_TONEJS";
//CHANGE_SEQUENCE_GUID_ROUTE
export const CHANGE_SEQUENCE_GUID_ROUTE = "CHANGE_SEQUENCE_GUID_ROUTE";

//LOGOUT
export const LOGOUT = "LOGOUT";

// state of tone.js
export const CHANGE_PLAYERS = "CHANGE_PLAYERS";
export const CHANGE_SLECTEDBIT = "CHANGE_SLECTEDBIT";
export const CHANGE_TRACKS = "CHANGE_TRACKS";
export const CHANGE_CURRENTASSETS = "CHANGE_CURRENTASSETS";
export const CHANGE_CURRENT_ID = "CHANGE_CURRENT_ID";
export const CHANGE_COUNT = "CHANGE_COUNT";
export const CHANGE_TRACK_NUM = "CHANGE_TRACK_NUM";

export const CHANGE_CURRENTASSETS_INDEX = "CHANGE_CURRENTASSETS_INDEX";
export const CHANGE_CURRENT_ID_INDEX = "CHANGE_CURRENT_ID_INDEX";
export const CHANGE_COUNT_INDEX = "CHANGE_COUNT_INDEX";
export const CHANGE_ASSET_VALUE = "CHANGE_ASSET_VALUE";

// SET_PLAYING
export const SET_PLAYING = "SET_PLAYING";
// SET_STARTING
export const SET_STARTING = "SET_STARTING";
// SET_BARS
export const SET_BARS = "SET_BARS";
// SET_BPM
export const SET_BPM = "SET_BPM";
// SET_TIME_SIG
export const SET_TIME_SIG = "SET_TIME_SIG";

// ASSET_SEARCH
export const ASSET_SEARCH_REQUEST = "ASSET_SEARCH_REQUEST";
export const ASSET_SEARCH_SUCCESS = "ASSET_SEARCH_SUCCESS";
export const ASSET_SEARCH_RESET = "ASSET_SEARCH_RESET";
export const ASSET_SEARCH_FAILURE = "ASSET_SEARCH_FAILURE";
export const AUTH_TOKEN = "AUTH_TOKEN";

// CREATE_PATTERN
export const CREATE_PATTERN_REQUEST = "CREATE_PATTERN_REQUEST";
export const CREATE_PATTERN_SUCCESS = "CREATE_PATTERN_SUCCESS";
export const CREATE_PATTERN_RESET = "CREATE_PATTERN_RESET";
export const CREATE_PATTERN_FAILURE = "CREATE_PATTERN_FAILURE";

// PATTERN_ASSET_LOAD
export const PATTERN_ASSET_LOAD_REQUEST = "PATTERN_ASSET_LOAD_REQUEST";
export const PATTERN_ASSET_LOAD_SUCCESS = "PATTERN_ASSET_LOAD_SUCCESS";
export const PATTERN_ASSET_LOAD_RESET = "PATTERN_ASSET_LOAD_RESET";
export const PATTERN_ASSET_LOAD_FAILURE = "PATTERN_ASSET_LOAD_FAILURE";

export const CHANGE_CREATED_PATTERN = "CHANGE_CREATED_PATTERN";

// ASSET EDITOR
export const CLEAR_EDITOR_RESPONSE_ACTION =
  "ASSETS/CLEAR_EDITOR_RESPONSE_ACTION";

export const THEME_CHANGER = "THEME_CHANGER";

// CHANGE_NEW_ADD_VALUE
export const CHANGE_NEW_ADD_VALUE = "CHANGE_NEW_ADD_VALUE";

// CHANGE_NEW_ADD_STATE
export const CHANGE_NEW_ADD_STATE = "CHANGE_NEW_ADD_STATE";

// NEW_REQUEST_BODY
export const NEW_REQUEST_BODY = "NEW_REQUEST_BODY";

// CREATE_ASSET
export const CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST";
export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_RESET = "CREATE_ASSET_RESET";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

// CHANGE_NEW_PATTERN_STATE
export const CHANGE_NEW_PATTERN_STATE = "CHANGE_NEW_PATTERN_STATE";

// CHANGE_ASSET_DATA
export const CHANGE_ASSET_DATA = "CHANGE_ASSET_DATA";

// CHANGE_ASSET_DATA_SIZE
export const CHANGE_ASSET_DATA_SIZE = "CHANGE_ASSET_DATA_SIZE";

/* *****************************    Pattern Player     ******************************* */
// CHANGE_POSITION
export const CHANGE_POSITION = "CHANGE_POSITION";
// CHANGE_LOCKED
export const CHANGE_LOCKED = "CHANGE_LOCKED";
// CHANGE_MUTE
export const CHANGE_MUTE = "CHANGE_MUTE";
// CHANGE_VOLUME
export const CHANGE_VOLUME = "CHANGE_VOLUME";
// CHANGE_BACK
export const CHANGE_BACK = "CHANGE_BACK";
// CHANGE_CURRENTASSETS_EMPTY
export const CHANGE_CURRENTASSETS_EMPTY = "CHANGE_CURRENTASSETS_EMPTY";

// PATTERN_LIST
export const PATTERN_LIST_REQUEST = "PATTERN_LIST_REQUEST";
export const PATTERN_LIST_SUCCESS = "PATTERN_LIST_SUCCESS";
export const PATTERN_LIST_RESET = "PATTERN_LIST_RESET";
export const PATTERN_LIST_FAILURE = "PATTERN_LIST_FAILURE";

// ASSET_LIST
export const ASSET_LIST_REQUEST = "ASSET_LIST_REQUEST";
export const ASSET_LIST_SUCCESS = "ASSET_LIST_SUCCESS";
export const ASSET_LIST_RESET = "ASSET_LIST_RESET";
export const ASSET_LIST_FAILURE = "ASSET_LIST_FAILURE";

// SET_SEARCH_PATTERN
export const SET_SEARCH_PATTERN_REQUEST = "SET_SEARCH_PATTERN_REQUEST";
export const SET_SEARCH_PATTERN_SUCCESS = "SET_SEARCH_PATTERN_SUCCESS";
export const SET_SEARCH_PATTERN_RESET = "SET_SEARCH_PATTERN_RESET";
export const SET_SEARCH_PATTERN_FAILURE = "SET_SEARCH_PATTERN_FAILURE";

// CHANGE_SEARCH_PATTERN
export const CHANGE_SEARCH_PATTERN = "SET_SEARCH_PATTERN";

// CHANGE_TRACK_VOLUME
export const CHANGE_TRACK_VOLUME = "CHANGE_TRACK_VOLUME";

// REMOVE_TRACK
export const REMOVE_TRACK = "REMOVE_TRACK";
