import React from "react";

const Index = () => {
  return (
    <div>
      <p>Setting</p>
    </div>
  );
};
export default Index;
